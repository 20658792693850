import { Dialog } from "@headlessui/react";
import Body from "components/Body";
import Icon from "components/Icon";
import classNames from "lib/classNames";
import useIsInViewport from "hook/useIsInViewport";
import Image from "next/image";
import React, { useRef, useState } from "react";

export default function FeaturesBlock({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const transitionDuration = 0.5;
  const transitionDelay = transitionDuration * 0.75;

  function getYoutubeVideoId(url) {
    const regExp =
      /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = url.match(regExp);

    return match && match[1].length === 11 ? match[1] : false;
  }

  const youtubeId = getYoutubeVideoId(data.field_video_link);

  return (
    <section className="bg-white py-28 overflow-hidden">
      <div ref={ref} className="container px-4 mx-auto">
        <h2 className="sr-only">{data.info}</h2>

        {data.body && (
          <div
            className={classNames(
              "prose opacity-0 transition-opacity duration-500",
              isInViewport && "opacity-100"
            )}
          >
            <Body value={data.body.processed} />
          </div>
        )}

        <div className="flex flex-col xl:flex-row gap-16 xl:gap-44">
          {data.field_paragraphs.length > 0 && (
            <div className="w-full xl:w-2/3 xl:order-1">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12">
                {data.field_paragraphs.map((paragraph, index) => {
                  const thisTransitionDelay = transitionDelay * index;

                  return (
                    <div key={paragraph.id} className="flex flex-col gap-6">
                      <div
                        className="block w-fit p-5 bg-primary rounded-md text-white transition-all"
                        style={{
                          opacity: isInViewport ? 100 : 0,
                          transform: `translateY(${
                            isInViewport ? 0 : "-1.5rem"
                          })`,
                          transitionDuration: `${transitionDuration}s`,
                          transitionDelay: `${thisTransitionDelay}s`,
                        }}
                      >
                        <Icon
                          name={paragraph.field_icon.field_component}
                          className="h-8 w-8"
                        />
                      </div>

                      <div
                        className="text-xl font-important font-medium transition-all"
                        style={{
                          opacity: isInViewport ? 100 : 0,
                          transform: `translateX(${
                            isInViewport ? 0 : "-1.5rem"
                          })`,
                          transitionDuration: `${transitionDuration}s`,
                          transitionDelay: `${thisTransitionDelay + 0.2}s`,
                        }}
                      >
                        {paragraph.field_title}
                      </div>

                      <div
                        className="prose max-w-none text-sm leading-relaxed text-secondary-dark transition-all"
                        style={{
                          opacity: isInViewport ? 100 : 0,
                          transform: `translateX(${
                            isInViewport ? 0 : "-1.5rem"
                          })`,
                          transitionDuration: `${transitionDuration}s`,
                          transitionDelay: `${thisTransitionDelay + 0.4}s`,
                        }}
                      >
                        <Body value={paragraph.field_text.processed} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {data.field_video_thumbnail && youtubeId && (
            <div className="flex-1 w-full xl:w-auto xl:order-0">
              <div
                className={classNames(
                  "max-w-2xl mx-auto opacity-0 transition-opacity duration-500",
                  isInViewport && "opacity-100"
                )}
              >
                <div className="aspect-w-16 aspect-h-9 xl:aspect-h-16 xl:aspect-w-10">
                  <Image
                    src={data.field_video_thumbnail.image_style_uri.hq}
                    {...data.field_video_thumbnail.resourceIdObjMeta}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                  />
                  <button
                    type="button"
                    className="group absolute inset-0 flex items-center justify-center bg-default/40"
                    onClick={() => setIsOpen(true)}
                  >
                    <span
                      className="flex items-center justify-center w-20 h-20 bg-white rounded-full group-hover:bg-primary transition-colors"
                      aria-hidden="true"
                    >
                      <span className="inline-block border-l-[15px] border-l-primary border-y-transparent border-y-[10px] group-hover:border-l-white transition-colors translate-x-1/4" />
                    </span>
                    <div className="sr-only">Play</div>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {youtubeId && (
          <Dialog
            as="div"
            className="relative z-10"
            open={isOpen}
            onClose={() => setIsOpen(false)}
          >
            <div className="fixed inset-0 bg-default/40" />
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-md shadow-md text-left align-middle transition-all">
                  <div className="aspect-w-16 aspect-h-9">
                    <iframe
                      src={`https://www.youtube.com/embed/${youtubeId}`}
                      title="Video METROPACK"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </Dialog>
        )}
      </div>
    </section>
  );
}
